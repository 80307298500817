h1,
h3 {
  margin: 0;
  padding: 0;
}

.jobs {
  margin-top: 36px;
  padding-left: 72px;
  @include media-below($bp-xl) {
    padding-left: 36px !important;
  }
}

.active-jobs-header {
  padding-left: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5rem;
  @include media-below($bp-xl) {
    padding: 0 0.75rem !important;
  }
}

.active-jobs-header-buttons {
  display: flex;
}

.active-jobs-header-buttons > button {
  margin-left: 18px;
}

.filter-vector {
  margin-left: 0.5rem !important;
}

.active-jobs-jobs {
  display: flex;
  margin-top: 39px;
  position: relative;
  width: 100%;
}

.active-jobs-jobs-a {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.active-jobs-jobs-arrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include media-below($bp-xl) {
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    padding-left: 0.75rem !important;
  }
}

.active-jobs-jobs-arrows button {
  margin: 9px 0;
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  cursor: pointer;
}
.active-jobs-jobs-no-jobs {
  position: relative;
  width: 100% !important;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 466px;
  margin: 0 auto;
  @include media-below($bp-xl) {
    width: 300px;
  }
}

.active-jobs-jobs-no-jobs h1 {
  margin-bottom: 18px !important;
}

.active-jobs-jobs-no-jobs p {
  margin-bottom: 36px !important;
}

.active-jobs-jobs-no-jobs-filters {
  position: absolute;
  right: 72px;
  top: 0;
}

.active-jobs-jobs-job {
  padding-left: 81px;
  display: flex;
  flex-direction: row;
  width: 100%;
  @include media-below($bp-xl) {
    padding-left: 0;
  }
}

.active-jobs-jobs-job .slick-slider,
.archived-jobs-jobs-job .slick-slider {
  width: 100% !important;
}

.active-jobs-jobs-job .slick-slider .slick-list .slick-track {
  transform: translate3d(0 0 0) !important;
}

.active-jobs-jobs-job .slick-track,
.archived-jobs-jobs-job .slick-track {
  margin: 0 !important;
}

.archived-jobs,
.job-details-applicants {
  margin-top: 56px;
}

.archived-jobs-header,
.job-details-applicants-header {
  padding-left: 81px;
  @include media-below($bp-xl) {
    padding: 0 0.75rem;
  }
}

.archived-jobs-jobs,
.job-details-applicants-applicants {
  display: flex;
  margin-top: 27px;
  position: relative;
}

.archived-jobs-jobs-a,
.job-details-applicants-applicants-a {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.archived-jobs-jobs-arrows,
.job-details-applicants-applicants-arrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.job-details-applicants-applicants-arrows button {
  margin: 9px 0;
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  cursor: pointer;
}

.archived-jobs-jobs-job,
.job-details-applicants-applicants-applicant {
  padding-left: 81px;
  // ovde je greska
  // display: flex;
  flex-direction: row;
  width: 100%;
  @include media-below($bp-xl) {
    padding: 0;
  }
}

.archive-jobs-no-active-jobs {
  padding-bottom: 136px !important;
}

.archive-job {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px;
  gap: 18px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  margin-right: 27px;
  transition: 0.3s;
  cursor: pointer;
}

.job-details-candidate {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  margin-right: 27px;
  transition: 0.3s;
  cursor: pointer;
  padding: 54px 72px !important;
}

.archive-job:hover,
.job-details-candidate:hover {
  scale: 1.05;
  border-color: $mainBlue !important;
  background-color: $mainBlueLight !important;
}

.archive-job-date p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.job-details-candidate-date p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
  margin-bottom: 18px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.archive-job-title h3,
.job-details-candidate-title h3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #226cb0;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.archive-job-image img {
  width: 49px;
  height: 39px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.job-details-candidate-experience {
  margin-bottom: 9px;
}

.archive-job-experience p,
.job-details-candidate-experience p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
  flex: none;
  order: 3;
  flex-grow: 0;
}

.slick-list {
  padding: 1.25rem !important;
  overflow-y: visible !important;
  @include media-below($bp-xl) {
  }
}

.slick-dots {
  display: none !important;
}

.slick-arrow {
  display: none !important;
}

.job-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 72px;
  width: 90% !important;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 18px;
  gap: 18px;
  margin-right: 36px;
  cursor: pointer;
  transition: 0.3s;
  @include media-below($bp-xl) {
    margin-right: 20px !important;
    padding: 36px !important;
  }
}

.job-card:hover {
  scale: 1.05;
  border-color: $mainBlue !important;
  background-color: $mainBlueLight !important;
}

.job-card-buttons-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px !important;
  gap: 10px !important;
  width: 36px !important;
  height: 36px !important;
  border: 1px solid #e4e4e4 !important;
  background-color: white;
  border-radius: 9px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.job-card-date p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.job-card-title h3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #226cb0;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.job-card-logo img {
  width: 61px;
  height: 49px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.job-card-experience p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  flex: none;
  order: 3;
  flex-grow: 0;
}

.job-card-buttons {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 18px;
  flex: none;
  order: 4;
  flex-grow: 0;
  @include media-below($bp-xl) {
    gap: 9px !important;
  }
}

.job-details-candidate-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin-bottom: 9px;
  width: 205px !important;
  flex-wrap: wrap;
}

.job-details-candidate-cv a {
  color: $mainBlue;
}

.job-details-candidate-buttons button {
  box-sizing: border-box;
  margin: 0 4.5px;
  padding: 9px;
  gap: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background-color: white;
  margin-bottom: 9px;
}

.job-card-buttons button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  gap: 10px;
  width: 76px;
  height: 38px;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.add-job {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-right: 5rem;
  padding-bottom: 2.5rem;
}

.add-job-btn,
.delete-filters-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  width: 201px;
  height: 51px;
  background: #226cb0;
  border-radius: 9px;
  margin-top: 9px;
  @include media-below($bp-xl) {
    width: 147px;
  }
}

.add-job-no-jobs {
  margin: 0 !important;
  padding: 0 !important;
}

.job-filters-header-container {
  display: flex;
  justify-content: space-between;
}

.job-filters-header {
  display: flex;
  align-items: center;
}

.job-filters-header-close {
  cursor: pointer;
}

.job-filters-header > * {
  margin-right: 0.25rem;
}

.job-filters-header img {
  width: 18px;
  height: 15.75px;
}

.job-filters-header sub {
  color: #226cb0;
}

.job-filters-sub-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
}

.job-filters-experience {
  margin-top: 18px;
  box-sizing: border-box;
}

.job-filters-experience-slider {
  margin-top: 5px;
}

.job-filters-technologies {
  margin-top: 18px;
}

.job-filters-employment-type {
  display: flex;
}

.job-filters-employment-type > button {
  margin-right: 0.5rem;
  margin-top: 18px;
}

.job-filters-search {
  margin-top: 18px;
  padding-bottom: 18px;
}

.job-filters-search > * {
  width: 100%;
}

.job-details {
  padding: 45px 72px 18px 223px;
  @include media-below($bp-xl) {
    padding: 18px 36px !important;
  }
}

.job-details-tech-logo {
  position: relative;
  left: -80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-below($bp-xl) {
    left: 0;
  }
}

.job-details-applicants {
  position: relative;
  left: -80px !important;
  @include media-below($bp-xl) {
    left: 0 !important;
  }
}

.job-details-tech-logo-title {
  display: flex;
  align-items: center;
}

.job-details-tech-logo-title-img {
  margin-right: 18px;
}

.job-details-tech-logo-title-sub sub {
  margin-left: 9px;
  font-size: 1.25rem;
  color: $mainBlue !important;
  font-weight: 600;
}

.job-details-tech-logo-date {
  @include media-below($bp-xl) {
    margin-bottom: 18px;
  }
}

.job-details-tech-logo-date p span {
  color: #9d9d9d;
}

.job-details-content-experience {
  margin-top: 18px;
}

.job-details-content-experience p {
  color: #272727;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.job-details-content-work-time {
  margin-top: 18px;
}

.job-details-content-work-time button {
  box-sizing: border-box;
  flex-direction: row;
  padding: 9px;
  gap: 10px;
  width: 76px;
  height: 38px;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 18px;
  cursor: pointer;
}

.job-details-content-content {
  margin-top: 18px;
}

.job-details-content-conten-description {
  margin-top: 18px;
}

.job-details-content-conten-description h3 {
  margin-bottom: 9px;
}

.job-details-content-conten-description ul {
  list-style: circle;
  padding-left: 36px;
}

.job-details-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
}

.job-details-expired-at {
  position: relative;
  left: -80px;
}

.job-details-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include media-below($bp-xl) {
    justify-content: center;
  }
}

.job-details-buttons > button {
  margin-left: 36px;
}

.job-details-buttons-link {
  color: $mainBlue;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.job-details-candidate-technologies {
  display: flex;
  justify-content: space-between;
  max-width: 208px;
  flex-wrap: wrap;
}

.hiddenAd {
  visibility: hidden !important;
}

.add-job-modal {
  padding: 36px !important;
  border: none !important;
  border-radius: 18px;
  min-height: 591px !important;
  width: 512px !important;
}

.add-job-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px !important;
}

.add-job-modal-header img {
  width: 18px;
  height: 18px;
}

.add-job-modal-header-title {
  display: flex;
  align-items: flex-end;
}

.add-job-modal-header-title > * {
  margin-right: 6px;
}

.add-job-modal-header-title-span {
  color: $mainBlue;
}

.add-job-modal-header-icon button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.add-job-modal-header-icon img {
  width: 9px !important;
  height: 12px !important;
}

.add-job-modal-stages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(591px - 36px - 36px - 20.8px - 18px) !important;
}

.add-job-modal-stage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(591px - 36px - 36px - 20.8px - 18px) !important;
}

.add-job-modal-stage-sub-card {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.add-job-modal-stage-sub-card input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.add-job-modal-stage-sub-card-third {
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.add-job-modal-stage-sub-card-third .tox-tinymce {
  height: 150px !important;
}

.job-details-content-conten-description p {
  display: block;
}

.add-job-modal-stage-sub-card label,
.add-job-modal-stage-sub-card-third label {
  margin-bottom: 4.5px !important;
}

.add-job-modal-stage-sub-card-title {
  margin-bottom: 9px;
  font-weight: bold;
}

.add-job-modal-stage-sub-card input,
.add-job-modal-stage-sub-card-third textarea {
  padding: 9px;
  border-radius: 7px;
  border: 1px solid #e4e4e4;
  outline: none;
}

.add-job-modal-stage-sub-card-third textarea {
  resize: none;
}

.add-job-modal-stage-sub-card-checkboxes {
  padding: 0 9px !important;
}

.add-job-modal-stage-sub-card-checkboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.add-job-modal-stage-sub-card-checkboxes .MuiFormControlLabel-root {
  width: calc(100% / 3) !important;
  margin: 0 !important;
}

.add-job-modal-stage-sub-card-buttons {
  display: flex;
}

.add-job-modal-stage-sub-card-buttons button {
  margin-right: 9px;
}

.add-job-modal-actions {
  display: flex;
  justify-content: space-between;
}

.add-job-modal-actions button {
  padding: 18px 72px !important;
}

.stats-job {
  width: 247px !important;
}
.stat-jobs {
  display: block !important;
  margin-top: -60px;
  padding-top: 0px;
  width: 100%;
  position: relative;
  left: -20px;
  @include media-below($bp-xl) {
    left: -7.5px;
  }
}

.apply-for-job {
  min-width: 512px !important;
}

.apply-for-job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.apply-for-job-header-left {
  display: flex;
  align-items: center;
}

.apply-for-job-header-left > * {
  margin-right: 6px !important;
}

.apply-for-job-header-left-image img {
  width: 18px;
  height: 18px;
}

.apply-for-job-header-left-image-title p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #272727;
}

.apply-for-job-header-left-image-title-sub sub {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: $mainBlue;
}

.apply-for-job-header-right button {
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent;
  cursor: pointer;
}

.apply-for-job-header-right button img {
  width: 9px !important;
  height: 10.5px !important;
}

.uploadCV-input {
  width: 100%;
  height: 140px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadCV-content-sub-container {
  display: flex;
  flex-direction: column;
}

.uploadCV-content-sub-container > p {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.uploadCV-input-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadCV-input-sub-container img {
  width: 22px;
  height: 22px;
}

.uploadCV-input-sub-container p {
  width: 332px;
  height: 40px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.11px;
  letter-spacing: 0em;
  text-align: center;
}

.apply-for-job-modal-form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.apply-for-job-modal-form-control {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100%;
}

.apply-for-job-modal-form-control input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.apply-for-job-modal-form-control label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  margin-bottom: 4.5px;
}

.apply-for-job-modal-form-control input,
.apply-for-job-modal-form-control select,
.apply-for-job-modal-form-control textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  gap: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  outline: none;
  font-size: 16px;
}

.apply-for-job-modal-form-control textarea {
  resize: none;
  font-size: 16px;
}

.apply-for-job-modal-form-control input[type="submit"] {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  background: #226cb0;
  color: white;
  border-radius: 9px;
  cursor: pointer;
  font-size: 16px;
}

.apply-for-job-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 27px !important;
}

.apply-for-job-buttons > button {
  padding: 18px 72px !important;
  border-radius: 9px;
  cursor: pointer;
}

.apply-for-job-buttons > button:first-child {
  background-color: white;
  border: 1px solid $mainBlue;
  color: $mainBlue;
}

.apply-for-job-buttons > button:last-child {
  background-color: $mainBlue;
  border: 1px solid $mainBlue;
  color: white;
}

.apply-for-job-header-title p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  margin-bottom: 9px !important;
}

.apply-for-job-header-sub-group-checkboxes {
  padding: 0 9px !important;
}

.apply-for-job-header-sub-group-checkboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.apply-for-job-header-sub-group-checkboxes .MuiFormControlLabel-root {
  width: calc(100% / 3) !important;
  margin: 0 !important;
}

.apply-for-job-modal-form-control-drag-and-drop {
  border: 1px solid #e4e4e4;
  padding: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.apply-for-job-modal-form-control-drag-and-drop p {
  text-align: center;
  font-size: 16px;
}

.apply-for-job-modal-form-control-drag-and-drop p button {
  background-color: white;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  color: $mainBlue;
  text-decoration: underline;
  font-size: 16px;
}

.create-job-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
  position: relative;
}

.create-job-page-content {
  max-width: 630px !important;
  min-width: 630px !important;
  @include media-below($bp-xl) {
    padding: 20px;
    min-width: 0 !important;
  }
}

.create-job-page-content-header {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 36px;
}

.create-job-page-content-header sub {
  color: $mainBlue;
}

.create-job-form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

.create-job-form-control label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  margin-bottom: 4.5px;
}

.create-job-form-control input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
}

.create-job-form-control-buttons {
  display: flex;
}

.create-job-form-control-buttons > button {
  margin-right: 9px;
}

.create-job-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.create-job-buttons-back {
  margin-left: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  background: #f4f4f4;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  margin-left: 9px;
  cursor: pointer;
  @include media-below($bp-xl) {
    margin-bottom: 9px !important;
  }
}

.create-job-buttons-forward {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  background: #226cb0;
  border-radius: 9px;
  border: none;
  color: white;
  margin-left: 9px;
  cursor: pointer;
  @include media-below($bp-xl) {
    margin-bottom: 9px !important;
  }
}

.create-job-go-back {
  position: absolute;
  right: 72px;
  bottom: 36px;
}

.create-job-go-back button {
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;
  margin: 0;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  text-decoration-line: underline;
  color: #226cb0;
  cursor: pointer;
}

.create-job-form-control-first-step-input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100%;
}

.create-job-form-control input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}