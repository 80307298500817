.schedule-page-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 46px;
  overflow-x: auto;
}

.schedule-page-main-header {
  font-family: Source Sans Pro;
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
}

.schedule-page-header {
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #226cb0;
  align-self: flex-end;
  margin-left: 5px;
}

.schedule-page-arrows-container {
  display: flex;
  gap: 18px;
  margin-top: 32px;
}

.schedule-page-arrow-container {
  display: flex;
  height: 45px;
  width: 45px;
  border-radius: 9px;
  border: 1px solid #e4e4e4;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.schedule-page-content-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  padding-top: 18px;
  padding-right: 59px;
  padding-bottom: 10px;
  grid-gap: 0px;
  width: 1305px;
}

@media only screen and (max-width: 361px) {
  .schedule-page-main-header {
    font-size: 18px;
  }

  .schedule-page-header{
    font-size: 14px;
  }

  .schedule-page-arrow-container{
    width: 36px;
    height: 36px;
  }

  .schedule-page-content-container{
    padding-right: 36px;
    width: 1250px;
  }
}
