.main-candidates-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  margin-top: 36px;
  padding-left: 72px;
}

.top-candidates-container {
  display: flex;
  justify-content: space-between;
  margin-left: 72px;
  margin-right: 72px;
}

.candidate-btn {
  margin-left: 20px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #226cb0;
  text-transform: none;
}

.all-white-btn {
  background: linear-gradient(0deg, #e8f7ff, #e8f7ff),
    linear-gradient(0deg, #226cb0, #226cb0);
}

.candidate-btn-mobile {
  @extend .candidate-btn;
  // overrride style from candidate-btn class
  margin-left: 10px;
}

.invite-btn {
  padding: 18px 72px;
  gap: 10px;
  height: 51px;
  width: 190px;
  border-radius: 9px;
  display: flex;
  align-self: flex-end;
  margin-left: 60px;
  margin-bottom: 36px;
}

.candidates-options-container {
  display: flex;
}

.candidates-header {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #272727;
}

.candidates-table {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  align-items: space-between;
}

.candidates-image {
  height: 14px;
  width: 16px;
  margin-left: 10px;
}

.candidate-image-mobile {
  position: "relative";
  top: -0.25;
}

.invite-btn-color {
  color: #fff;
  background-color: #226cb0;
}

.jobs-candidates-container {
  max-height: 837px;
  overflow-y: auto;
  overflow-x: hidden;
}

.jobs-candidates-slider {
  display: flex;
  margin-top: 31px;
}

.jobs-candidates-slider .slick-track {
  margin: 0 !important;
}

.jobs-candidates-slider .slick-slider {
  width: 100% !important;
}

.jobs-candidates-top-container {
  display: flex;
  align-items: center;
  margin-left: 72px;
}

.jobs-candidates-title {
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.02em;
  margin-left: 18px;
}

.jobs-candidates-numberOfApplicants {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #226cb0;
  margin-left: 5px;
}

.jobs-candidates-image {
  width: 49px;
  height: 39px;
}

.top-cnd {
  margin-top: 39px;
}

.top-cnd > div:not(:first-child) {
  margin-top: 79px;
}

.filter-date-container {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.filter-date-container input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.filter-date-container input {
  border: 1px solid gray;
  border-radius: 4px;
  height: 40px;
  padding-left: 12px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #9d9d9d;
  margin-top: 5px;
}

.left-move-candidateAd {
  margin-left: -0.75rem !important;
}

.left-move-candidateAd-page {
  margin-left: 16px;
}

.left-move-candidateAd-page-2 {
  margin-left: 42px;
}

.jobs-search-field-responsive {
  min-width: 350px;
  border: 1px solid #226cb0;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  position: absolute;
  top: 100px;
  right: 9px;
  z-index: 1000;
  &.smaller {
    min-width: 250px !important;
  }
}

.jobs-search-field-responsive::placeholder {
  font-size: 1rem;
  color: #9d9d9d;
  font-style: italic;
}

.jobs-search-field {
  min-width: 700px !important;
  border: 1px solid #226cb0;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  position: absolute;
  top: 112px;
  right: 72px;
  z-index: 1000;
}

.jobs-search-field::placeholder {
  font-size: 1rem;
  color: #9d9d9d;
  font-style: italic;
}

.candidate-search-field {
  width: 816px;
  border: 1px solid #226cb0;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  position: absolute;
  top: 112px;
  right: 70px;
  z-index: 1000;
}

.candidate-search-field::placeholder {
  font-size: 1rem;
  color: #9d9d9d;
  font-style: italic;
}

.cls1 .slick-track {
  margin-left: 35px !important;
}

.cls2 .slick-track {
  margin-left: 20px !important;
}

.cls3 .slick-track {
  margin-left: 0px !important;
}

.cls4 .slick-track {
  margin-left: 45px !important;
}

.candidates-pagination {
  margin-top: 20px;
  margin-left: 350px;
  padding-bottom: 20px;
}

.candidates-cv {
  width: 500px;
  height: 610px;
  margin-right: 72px;
}

@media only screen and (max-width: 600px) {
  .jobs-candidates-title {
    font-size: 18px;
    margin-left: 10px;
  }
  .jobs-candidates-image {
    width: 40px;
    height: 30px;
  }
  .jobs-candidates-numberOfApplicants {
    font-size: 14px;
  }
}

@media only screen and (max-width: 361px) {
  .jobs-candidates-slider {
    flex-direction: column-reverse;
  }

  .main-candidates-container {
    padding-left: 0px;
  }

  .top-candidates-container {
    margin-left: 36px;
    margin-right: 29px;
  }
  .candidates-textField {
    margin-left: 36px;
  }

  .jobs-candidates-top-container {
    margin-left: 36px;
  }

  .left-move-candidateAd-page {
    margin-left: -12px;
  }
}
