.selections {
  padding-left: 153px;

  @include media-below($bp-xl) {
    padding-left: 36px !important;
  }
}

.level-header {
  padding-top: 72px;
  padding-bottom: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: red;

  @include media-below($bp-xl) {
    padding: 20px 0.75rem 39px 0.75em !important;
  }
  .userPageBtn {
    margin-right: 72px;
    @include media-below($bp-xl) {
      margin-right: 10px;
    }
  }
}

.selection-levels {
  overflow-x: scroll;
  padding-bottom: 100px;
}

.fixed-right {
  text-align: right;
  margin-left: auto;
  position: absolute;
  right: 100px;
  top: 123px;
}

.level-header-subheader {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding-left: 0.3rem;
  color: #226cb0;
  letter-spacing: 0.02em;

  @include media-below($bp-xl) {
    font-size: 14px;
  }
}

h1,
h3 {
  @include media-below($bp-xl) {
    font-size: 18px;
  }
}

.level-header-spliter {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding-left: 0.3rem;
  color: #272727;
  letter-spacing: 0.02em;

  @include media-below($bp-xl) {
    font-size: 18px;
  }
}

.selection-levels-processes {
  display: flex;
  position: relative;
  min-height: 53vh;
}

.selection-levels-processes-process {
  display: flex;

  @include media-below($bp-xl) {
    padding-left: 0;
  }
}

.selection-card {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  height: fit-content;
  padding: 36px;
  background: #f4f4f4;
  border: 1px solid #e4e4e4;
  border-radius: 18px;
  gap: 18px;
  margin-right: 36px;
  transition: background-color 0.35s ease;
  
  @include media-below($bp-xl) {
    margin-right: 20px !important;
    padding: 18px !important;
  }
  &.over{
    background-color: $mainBlueLight !important;
    transition: background-color 0.35s ease;
  }
}

.sel-item:hover {
  transition: .25s;
  scale: 1.05;
  border-color:$mainBlue;
  
  .sel-item-inner{
    background-color: $mainBlueLight !important;
    transition: .25s;
    .status button{
      transition: .25s;
      &.unsucc{
        background: #FFEAEE !important;
        color: #D72228 !important;
      }
      background-color: $mainBlueLight !important;
  }}

}

.bg-danger {
  background-color: #272727;
}

.grey {
  color: #e4e4e4;
}

.selection-item {
  display: flex;
  flex-direction: row;
  justify-content: left;
  vertical-align: top;
  align-items: left;
  width: 400px;
  padding: 18px 36px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 18px;
  gap: 18px;
  margin-right: 36px;
}

.selection-item-date p {
  text-align: right;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #272727;
  flex: none;
  order: 4;
  flex-grow: 0;
}

.selection-card-title {
  display: flex;
  justify-content: space-between;
  img {
    width: 12px;
  }
  .c-btn {
    background-color: white !important;
  }
  h3 {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #272727;
    flex: none;
    order: 0;
    flex-grow: 0;

    @include media-below($bp-xl) {
      font-size: 18px;
    }
  }
}

.selection-item-name,
.selection-item-date {
  margin: auto 0 !important;
}

.selection-item-name p {
  height: 20px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #226cb0;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.selection-item-buttons button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  padding: 9px;
  gap: 10px;
  min-width: 76px;
  height: 38px;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.sel-item{
  border-radius: 18px;
  border: 1px solid #e4e4e4;
  overflow: hidden;
  transition: .25s;
}
.sel-item-scheduler{
  display: flex;
  justify-content: space-between;
  background: #E4E4E4;

  p{
    padding: 12.5px 25px 12.5px 0px !important;
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #272727;
  }
}
.sel-item-inner {
  transition: .25s;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 36px;
  gap: 18px;
  cursor: pointer;
  width: 458px;
  background: #ffffff;
  // transition: 0.3s;
  @include media-below($bp-xl) {
    justify-content: space-between;
    padding: 18px;
    width: 303px;
  }
  
    .status button{ transition: .25s;}
}

.sel-item-inner-no-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 36px;
  gap: 18px;
  width: 458px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 18px;
  transition: 0.3s;
  @include media-below($bp-xl) {
    justify-content: space-between;
    padding: 18px;
    width: 303px;
  }
}

.sel-item-inner .status {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  flex: none;
  order: 0;
  flex-grow: 0;
  @include media-below($bp-xl) {
    font-size: 14px;
    order: 1;
  }
}

.sel-item-inner .date-name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 18px;
  cursor: pointer;
  width: 100%;
  order: 1;
  @include media-below($bp-xl) {
    flex-direction: column;
    font-size: 14px;
    order: 0;
    flex-grow: 0;
    gap: 4px;
  }
}

.sel-item-inner .date-name .date {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #272727;
  flex: none;
  font-size: 14px !important;
  order: 0;
}

.full-name {
  height: 20px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  color: #226cb0;
  flex: 3 0 auto;
  order: 1;
  @include media-below($bp-xl) {
    text-align: left !important;
    font-size: 14px !important;
  }
}

.full-name p,
.sel-item-inner .date-name .date p {
  @include media-below($bp-xl) {
    font-size: 14px !important;
  }
}

.sel-item-inner .status button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  padding: 9px;
  gap: 10px;
  min-width: 76px;
  height: 38px;
  border: 1px solid #e4e4e4;
  background: white;
  border-radius: 9px;
  flex: none;
  order: 0;
  flex-grow: 0;
  @include media-below($bp-xl) {
    font-size: 14px !important;
  }
}

.active-process {
  scale: 1.05;
  border-color: $mainBlue !important;
  background-color: $mainBlueLight !important;
}

.active-process-card {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 18px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 18px;
  gap: 18px;
  margin-right: 36px;
  @include media-below($bp-xl) {
    margin-right: 20px !important;
    padding: 36px !important;
  }
}

.active-process-tip {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 91.5px;
  width: 80rem;
  background: #ffffff;
  gap: 18px;
  margin-right: 36px;
  @include media-below($bp-xl) {
    margin-right: 20px !important;
    padding: 36px !important;
    width: 100%;
  }
}

.active-process-tip h3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #272727;
}

.active-process-tip p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #272727;
}

.active-process-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0px;
  gap: 18px;
  flex-wrap: wrap;
}

.active-process-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 34px;
  gap: 18px;

  @include media-below($bp-xl) {
    margin-right: 20px !important;
    padding: 36px !important;
  }
}

.active-process-card-date p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.active-process-card-number p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  background: conic-gradient(
    from 73.66deg at 50% 50%,
    #226cb0 0deg,
    #ba6fb9 106.88deg,
    #5e9fdb 228.75deg,
    #226cb0 360deg
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  flex: none;
  order: 6;
  flex-grow: 0;
  z-index: 6;
}
.change-interbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 35px;
}
.interbtn{
  height: 27.5px;
  background-color: $mainBlue !important;
  color: white !important;
  font-size: 12px !important;
  border-radius: 7.5px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.unsucc{
    background: #FFEAEE !important;
    color: #D72228 !important;
  
}

.active-process-card-buttons {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 18px;
  flex: none;
  order: 4;
  flex-grow: 0;

  @include media-below($bp-xl) {
    gap: 9px !important;
  }
}

.active-process-card-buttons button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  gap: 10px;
  height: 38px;
  background: transparent;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.active-process-card-link {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #226cb0;
  flex: none;
  order: 4;
  flex-grow: 0;
  z-index: 4;
}

.active-process-card-logo img {
  flex: none;
  order: 0;
  flex-grow: 0;
}

.active-process-card-logo {
  margin-left: auto;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.modal-content.interviewDialog{
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin: 20px 0px 25px 0px;
  
}
.interview-btn{
  width: 220px !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
  text-align: left;
  padding-left: 20px;
}