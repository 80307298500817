.pdf-viewer-main {
    position: sticky;
    top: 2rem;
    margin-bottom: 10rem;
}

.pdf-viewer-controls-cont {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-viewer-controls {
    width: 100%;
    display: flex;
}

.pdf-viewer-page {
    width: fit-content;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    overflow: hidden;
}

.pdf-loading-spinner-box {
    width: 480px;
    height: 678px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}