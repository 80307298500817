.candidate-card-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px;
  gap: 18px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 18px;
  width: 354px;
  height: 238px;
  margin-right: 27px;
}

@media only screen and (max-width: 480px) {
  .candidate-card-container{
    width: 300px;
  }
}

@media only screen and (max-width: 430px) {
  .candidate-card-container{
    width: 240px;
  }
}

.candidate-card-container:active {
animation-timing-function: ease-in-out;
animation-duration: 300ms;
}

.candidate-card-container:hover{
  cursor: pointer;
}

.candidate-card-tecnologies-container {
  display: flex;
}

.candidate-card-date {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
}

.candidate-card-applicant-name {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #226cb0;
}

.candidate-card-years {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
}

.candidate-card-techologies {
  display: flex;
  justify-content: space-between;
  gap: 18px;
}
