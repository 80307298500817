.day-details-sub-container {
  padding-top: 46px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 36px;
}

.day-datails-title-container {
  display: flex;
  align-items: center;
  height: fit-content;
}

.day-details-calendar-image {
  width: 15.75px;
  height: 18px;
}

.day-details-main-header {
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-left: 9.25px;
}

.day-details-header {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #226cb0;
  margin-left: 5px;
  align-self: flex-end;
}

.day-details-close-btn {
  margin-left: 208px;
  cursor: pointer;
}

.day-details-time {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #f4f4f4;
  padding: 8px;
  border-radius: 10px;
}

.day-details-line {
  height: 1px;
  width: 100%;
  background-color: #f4f4f4;
  margin-top: 19px;
  margin-bottom: 19px;
}

.day-details-name {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #272727;
  margin-left: 6px;
  width: 111px;
}

.day-details-applicant {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #226cb0;
  margin-left: 72px;
  width: 110px;
  cursor: pointer;
  text-decoration: none;
}

.day-details-link {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 36px;
  width: 120px;
  border-radius: 9px;
  padding: 18px 36px 18px 36px;
  color: linear-gradient(0deg, #226cb0, #226cb0),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #226cb0;
  margin-left: 28px;
  cursor: pointer;
  text-decoration: none;
}

.day-details-link span {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #226cb0;
  text-transform: uppercase;
}

.day-details-content-sub-container > div:first-child {
  margin-top: 37px;
}

.day-details-arrow-container {
  display: flex;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.day-details-arrow-container-p {
  display: flex;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: rotate(-180deg);
}

@media only screen and (max-width: 361px) {
  .day-details-applicant {
    margin-left: 10px;
  }
  .day-details-sub-container {
    padding: 32px;
  }
  .day-details-link {
    width: 36px;
    height: 36px;
    gap: 0px;
    padding: 11px;
    margin-left: 20px;
  }
  .day-details-name {
    width: 66px;
  }
  .day-details-applicant {
    width: 97px;
  }
  .day-details-close-btn {
    margin-left: 12px;
  }

  .day-details-main-header {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .day-details-header{
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;    
  }
  .day-details-header{
    align-self: center;
  }
}

.css-ypiqx9-MuiDialogContent-root{
  padding: 0px !important;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
  padding: 0px !important;
}