.patterns {
  padding: 0 72px;
  @include media-below($bp-xl) {
    padding: 0 18px;
  }
}

.patterns-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding-left: calc(144px - 72px);
  margin-bottom: 18px !important;
  @include media-below($bp-xl) {
    padding-left: 18px;
  }
}

.pattern-header-active-button {
  background-color: $mainBlueLight !important;
}

.patterns-header button {
  margin-left: 14px;
}

.patterns-cards {
  padding: 0 calc(138px - 72px) 0 calc(144px - 72px);
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  margin-bottom: 18px !important;
  @include media-below($bp-xl) {
    padding: 0 18px !important;
    flex-direction: column !important;
  }
}

.pattern-card-parent {
  width: calc(100% / 3) !important;
  margin-bottom: 36px;
  padding-right: 36px;
  @include media-below($bp-xl) {
    width: 100% !important;
    padding: 0 !important;
  }
}

/* PATTERN CARD */
.pattern-card-with-icon {
  position: relative;
}

.pattern-card {
  position: relative;
  box-sizing: border-box;
  padding: 72px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 18px;
  width: 100% !important;
  transition: 0.3s;
  cursor: pointer;
}

.pattern-card:hover {
  scale: 1.05;
  border-color: $mainBlue !important;
  background-color: $mainBlueLight !important;
}

.pattern-card-edit {
  position: absolute;
  top: 9px !important;
  right: 9px !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 100;
}

.pattern-card-date p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.pattern-card-title p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: $mainBlue;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
}

.pattern-card-selection-proccess {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  gap: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
}

.pattern-card-date,
.pattern-card-title,
.pattern-card-selection-proccess {
  display: flex !important;
  justify-content: center !important;
}

.patterns-button {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-right: 5rem;
  padding-bottom: 2.5rem;
}

/* AD DETAILS */
.pattern-details {
  padding: 42px 36px 72px 36px !important;
  @include media-below($bp-xl) {
    margin-top: 9px;
  }
}

.pattern-details-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include media-below($bp-xl) {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    justify-content: flex-start;
  }
}

.pattern-details-header p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
}

.pattern-details-header p span {
  color: #9d9d9d;
}

.pattern-details-card {
  margin: 41px auto 0 auto !important;
  padding: 0 405px !important;
  @include media-below($bp-xl) {
    margin: 9px auto 0 auto !important;
    padding: 0 !important;
  }
}

.pattern-details-card-title {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  @include media-below($bp-xl) {
    margin-top: 9px;
  }
}

.pattern-details-card-title-title h1 {
  font-size: 36px;
  margin-right: 4px;
  @include media-below($bp-xl) {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
}

.pattern-details-card-title-sub {
  font-size: 24px;
  color: $mainBlue;
  font-weight: 600;
  @include media-below($bp-xl) {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
}

.pattern-details-card-sub-card {
  margin-bottom: 18px;
}

.pattern-details-card-sub-card-title {
  margin-bottom: 10px;
}

.pattern-details-card-screening-title p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
}

.pattern-details-card-sub-card-emails {
  display: flex;
  flex-wrap: wrap;
}

.pattern-details-card-sub-card-emails > div {
  margin-right: 9px;
  margin-bottom: 4px;
}

.pattern-details-card-sub-card-emails-email {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.pattern-details-card-sub-card-add-email {
  display: flex;
}

.pattern-details-card-sub-card-add-email input,
.pattern-details-card-sub-card-add-email select {
  margin-right: 18px;
  flex: 50;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  outline: none;
}

.pattern-details-card-sub-card-add-email button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #226cb0;
  border-radius: 9px;
  flex: 1;
  cursor: pointer;
  transition: 0.3s;
}

.pattern-details-card-sub-card-add-email button:hover {
  background-color: $mainBlueLight;
}

.pattern-details-card-sub-card-add-email button img {
  width: 12px;
  height: 12px;
}

.pattern-details-card-sub-card-message-pattern {
  display: flex;
  flex-direction: column;
  height: 256px !important;
  border: 2px solid#ccc !important;
  background: rgb(224, 224, 224) !important;
  border-radius: 6px;
  padding: 1rem;
}

.pattern-details-card-sub-card-message-pattern ul {
  list-style: disc;
  padding: 1rem 2rem;
}

.pattern-details-card-sub-card-message-pattern textarea {
  resize: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px;
  gap: 10px;
  background: #f4f4f4;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  min-height: 256px;
}

.pattern-details-card-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pattern-details-card-buttons > * {
  margin-left: 18px !important;
}

.custom-modal {
  padding: 36px !important;
  border: none !important;
  border-radius: 18px;
}

.add-pattern-modal {
  width: 512px;
  min-height: 618px;
}

.add-pattern-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.add-pattern-modal-header-title {
  display: flex;
  align-items: center;
}

.add-pattern-modal-header-title > * {
  margin-right: 4px;
}

.add-pattern-modal-header-title-image img {
  width: 18px;
  height: 18px;
}

.add-pattern-modal-header-title-title p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #272727;
}

.add-pattern-modal-header-title-sub sub {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: $mainBlue;
}

.add-pattern-modal-header-close img {
  width: 9px;
  height: 10.5px;
  cursor: pointer;
}

.add-pattern-modal-form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.add-pattern-modal-form-control .tox {
  height: 300px !important;
}

.add-pattern-modal-form-control label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  margin-bottom: 4.5px;
}

.add-pattern-modal-form-control input,
.add-pattern-modal-form-control select,
.add-pattern-modal-form-control textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  gap: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  outline: none;
}

.add-pattern-modal-form-control textarea {
  resize: none;
}

.add-pattern-modal-form-control input[type="submit"] {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  background: #226cb0;
  color: white;
  border-radius: 9px;
  cursor: pointer;
}

.edit-pattern-modal {
  width: 512px;
  min-height: 618px;
}

.edit-pattern-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.edit-pattern-modal-header-title {
  display: flex;
  align-items: center;
}

.edit-pattern-modal-header-title > * {
  margin-right: 4px;
}

.edit-pattern-modal-header-title-image img {
  width: 18px;
  height: 18px;
}

.edit-pattern-modal-header-title-title p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #272727;
}

.edit-pattern-modal-header-title-sub sub {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: $mainBlue;
}

.edit-pattern-modal-header-close img {
  width: 9px;
  height: 10.5px;
  cursor: pointer;
}

.edit-pattern-modal-form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.edit-pattern-modal-form-control .tox {
  height: 300px !important;
}

.edit-pattern-modal-form-control label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  margin-bottom: 4.5px;
}

.edit-pattern-modal-form-control input,
.edit-pattern-modal-form-control select,
.edit-pattern-modal-form-control textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  gap: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  outline: none;
}

.edit-pattern-modal-form-control textarea {
  resize: none;
}

.edit-pattern-modal-form-control input[type="submit"] {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 72px;
  gap: 10px;
  background: #226cb0;
  color: white;
  border-radius: 9px;
  cursor: pointer;
}

/* CUSTOM-FILTER-DRAWER */
.custom-drawer {
  display: flex;
  height: 100% !important;
  flex-direction: column;
  justify-content: space-between;
}

.custom-filter-drawer-header-container {
  display: flex;
  justify-content: space-between;
}

.custom-filter-drawer-header {
  display: flex;
  align-items: center;
}

.custom-filter-drawer-header-close {
  cursor: pointer;
}

.custom-filter-drawer-header > * {
  margin-right: 0.25rem;
}

.custom-filter-drawer-header img {
  width: 18px;
  height: 15.75px;
}

.custom-filter-drawer-header sub {
  color: #226cb0;
}

.custom-filter-drawer-content {
  margin-top: 18px !important;
  box-sizing: border-box;
}

.custom-drawer-sub-card {
  margin-bottom: 18px;
}

.custom-drawer-sub-card-label {
  margin-bottom: 10px;
}

.custom-drawer-sub-card-label p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
}

.custom-drawer-sub-card-content-sub {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100% !important;
}

.custom-drawer-sub-card-content-sub input {
  width: 100% !important;
}

.custom-drawer-sub-card-content-input-1::-webkit-calendar-picker-indicator,
.custom-drawer-sub-card-content-input-2::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.custom-drawer-sub-card-content-sub-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  margin-bottom: 4.5px;
}

.custom-drawer-sub-card-content input[type="date"] {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  gap: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
}

.custom-drawer-submit {
  margin-top: 18px;
  padding-bottom: 18px;
}

.custom-drawer-submit > * {
  width: 100%;
}
