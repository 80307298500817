.px36-heading {
  padding-top: 36px !important;
  padding-left: 144px;
  line-height: 32px;

  @include media-below($bp-xl) {
    padding-left: 36px !important;
  }
}
.section-header {
  padding-bottom: 27px !important;
}
.stats-section:not(:last-of-type) {
  padding-left: 144px;
  padding-right: 72px;
  margin-top: 83px;

  @include media-below($bp-xl) {
    padding: 20px 36px !important;
  }

  h3 {
    font-size: 24px;
  }

  .stats-items {
    display: flex;
    justify-content: flex-start;
    border: 1px solid #e4e4e4;
    border-radius: 18px;
    width: 920px;
    overflow: hidden;

    .stats-item {
      width: 230px;
      position: relative;

      &:not(:last-of-type):after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 60%;
        width: 1.75px;
      }

      &:first-of-type:after {
        background-color: #bfdbf5;
      }
      &:nth-of-type(2):after {
        background-color: #1e92d0;
      }
      &:nth-of-type(3):after {
        background-color: $mainBlue;
      }

      .stats-item-content {
        padding: 36px;
        background-color: white;

        p {
          margin-top: 9px;
          font-size: 16px;
          color: $mainBlue;
          font-weight: 600;
        }
      }

      .bottom-static {
        height: 35px;
        width: 100%;
      }

      &:first-of-type {
        .bottom-static {
          background-color: $mainBlueLight !important;
        }
      }
      &:nth-of-type(2) {
        .bottom-static {
          background-color: #bfdbf5 !important;
        }
      }
      &:nth-of-type(3) {
        .bottom-static {
          background-color: #1e92d0 !important;
        }
      }
      &:last-of-type {
        .bottom-static {
          background-color: $mainBlue !important;
        }
      }
    }
  }

  .stats-items-dynamic {
    display: flex;
    justify-content: flex-start;
    // margin-top: 27px;
    border: 1px solid #e4e4e4;
    border-radius: 18px;
    width: 1160px;
    overflow: hidden;

    .stats-item {
      width: 290px;
      position: relative;

      &:not(:last-of-type):after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 60%;
        width: 1.75px;
      }

      &:first-of-type:after {
        background-color: #bfdbf5;
      }
      &:nth-of-type(2):after {
        background-color: #1e92d0;
      }
      &:nth-of-type(3):after {
        background-color: $mainBlue;
      }

      .stats-item-content {
        padding: 36px;
        background-color: white;

        p {
          margin-top: 9px;
          font-size: 16px;
          color: $mainBlue;
          font-weight: 600;
        }
      }

      .bottom-dynamic {
        height: 35px;
        width: 100%;
      }

      .bottom-dynamic {
        display: flex;
        background-color: $white;
        border-top: 1px solid #e4e4e4;
        .bottom-loader-indicator {
          // width: 85%;
          border-bottom-right-radius: 18px;
        }
      }

      &:first-of-type {
        .bottom-dynamic {
          .bottom-loader-indicator {
            background-color: $mainBlueLight !important;
          }
        }
      }
      &:nth-of-type(2) {
        .bottom-dynamic {
          .bottom-loader-indicator {
            background-color: #bfdbf5 !important;
          }
        }
      }
      &:nth-of-type(3) {
        .bottom-dynamic {
          .bottom-loader-indicator {
            background-color: #1e92d0 !important;
          }
        }
      }
      &:last-of-type {
        .bottom-dynamic {
          .bottom-loader-indicator {
            background-color: $mainBlue !important;
          }
        }
      }
    }
  }
}

.stats-section:first-of-type {
  margin-top: 35px !important;
}
.stats-section {
  @include media-below($bp-xl) {
    margin-top: 20px !important;
  }
}
.stats-section:not(:last-of-type) {
  @include media-below($bp-xl) {
    overflow-x: auto;
  }
}
.stats-section:last-of-type {
  padding-bottom: 0px !important;
  margin-top: 83px;
}
.stats-section:last-of-type .section-header {
  padding: 0px !important;
  margin-bottom: 0px;
  padding-left: 144px;
  @include media-below($bp-xl) {
    padding: 20px 36px !important;
  }
}
.job-count {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  gap: 10px;

  width: 113px;
  height: 38px;

  /* Gray E4 */

  border: 1px solid #e4e4e4;
  border-radius: 9px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.stat-jobs {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
