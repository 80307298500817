.cv-container-main {
    padding-top: 2rem;
    display: flex !important;
}

.cv-container-rightcol {
    position: relative;
    width: 40%;
    padding: 2rem;
}

.cv-container-header-main {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
}

.cv-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cv-container-header-left {
    display: flex;
    align-items: center;
}
  
.cv-container-header-left > * {
    margin-right: 6px !important;
}

.cv-container-header-left-image img {
    width: 25px;
    height: 25px;
    margin-top: 5px;
}
  
.cv-container-header-left-image-title p {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.02em;
    color: #272727;
}
  
.cv-container-header-left-image-title-sub sub {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: $mainBlue;
}

.cv-container-main-content {
    display: flex;
    flex-direction: column;

    padding: 2rem 0.5rem;
}

.cv-container-main-desc {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;

    margin-bottom: 1rem;
}

.cv-container-main-actions {
    display: flex;
    width: 100%;

    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 1;
}

.cv-continue-btn {
    text-transform: uppercase;

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px 50px;
    margin-left: 20px;
    margin-right: 14rem;

    gap: 10px;
    font-weight: 600;
    border: 1px solid #226cb0;
    background-color: white;
    color: #226cb0;
    
    border-radius: 9px;
    cursor: pointer;
}

.cv-continue-btn-active {
    text-transform: uppercase;

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px 50px;
    margin-left: 20px;
    margin-right: 14rem;

    gap: 10px;
    background: #226cb0;
    border-radius: 9px;
    border: none;
    color: white;
    cursor: pointer;
}

.cv-back-btn {
    margin-left: auto;

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px 50px;
    gap: 10px;
    background: #f4f4f4;
    border: 1px solid #e4e4e4;
    border-radius: 9px;
    cursor: pointer;
}
  
//General-------------------------------------------------
.cv-form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
//CrateCVProfileStep--------------------------------------

.cv-profile-main {
    width: 100%;

    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1.5rem;

    animation: fade-in .5s ease forwards;
}

.cv-profile-col1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 50px;
}

.cv-profile-row {
    display: flex;
    flex-direction: row;
}


//CrateCVExperienceStep-----------------------------------
@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}

.cv-exp-form-main {
    display: flex;
    flex-direction: column;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 2rem;

    animation: fade-in .5s ease forwards;
}

.cv-exp-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cv-exp-form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cv-exp-form-left-col {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cv-exp-proj-form-main {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 1rem;
    animation: fade-in .5s ease forwards;
}

.cv-exp-proj-form-col {
    width: 100%;
    display: flex;
    flex-direction: column;
}

//CrateCVSkillsStep---------------------------------------
.cv-skills-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cv-skills-form-main {
    display: flex;
    flex-direction: column;

    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1.5rem;

    animation: fade-in .5s ease forwards;
}

.cv-skills-form-techortool {
    display: flex;
    align-items: center;
}

//CrateCVEducationStep-----------------------------------------

.cv-education-form-main {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 1rem;
    animation: fade-in .5s ease forwards;
}

//CrateCVFinishStep-----------------------------------------

.cv-finish-main-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cv-finish-back-btn {
    padding: 18px 52px !important;
    margin-left: 20px !important;
    margin-right: 15px !important;
  
    width: 212px !important;
    height: 51px !important;
  

    border-radius: 9px !important;
  
    font-family: "Source Sans Pro" !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 15px !important;

    letter-spacing: 0.04em !important;
    border: 1px solid #226cb0 !important;
    background-color: white !important;
    color: #226cb0 !important;
  } 
  