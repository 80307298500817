.main-candidate-container {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  padding-right: 72px;
}

.top-candidate-container {
  display: flex;
  justify-content: space-between;
  margin-left: 144px;
}

.candidate-header {
  height: 36px;
  font-family: Source Sans Pro;
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #272727;
}

.separation-line {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 20px;
  align-self: flex-end;
}

.candidate-lower-header {
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #226cb0;
  align-self: flex-end;
}
.candidate-option-container {
  display: flex;
  height: 38px;
}

.content-candidate-container {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin-left: 144px;
}

.technologies-candidate-container {
  display: flex;
  margin-top: 18px;
}

.technology-candidate-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
}

.technology-candidate-card:not(:last-child) {
  margin-right: 18px;
}

.comment-container {
  width: 612px;
  height: 404px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  margin-top: 16px;
}

.candidate-informations-container {
  display: flex;
  flex-direction: column;
}

.candidate-informations-sub-container {
  margin-top: 36px;
}

.informations-candidate-header {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
}

.candidate-property-container {
  display: flex;
  flex-direction: column;
}

.candidate-property {
  margin-top: 18px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.candidate-property-value {
  @extend .candidate-property;
  color: #1e92d0;
}

.candidate-informations-sub-container {
  display: flex;
}

.comment-container {
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 36px;
}

.comment-sub-container {
  display: flex;
  align-items: center;
}

.comment-sub-container:not(:first-child) {
  margin-top: 36px;
}

.comment-sender {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid;
  border-color: #226cb0;
}

.comment-sender p {
  color: #226cb0;
}

.comment-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 9px;
  gap: 9px;
  height: fit-content;
  background: #f4f4f4;
  border-radius: 12px;
  margin-left: 18px;
  max-width: 400px;
}

.comment-message-content {
  align-self: flex-start;
  height: 20px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}

.comment-message-date {
  align-self: flex-end;
  height: 15px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
}

.comment-separation-line {
  width: 100%;
  height: 0px;
  border: 1px solid #e4e4e4;
  background: #e4e4e4;
}

.send-comment-container {
  margin-top: 18px;
}

.send-comment-container p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9d9d9d;
}

.send-comment-sub-container {
  display: flex;
  margin-top: 9px;
  height: 56px;
  margin-bottom: 36px;
}

.comment-send-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 36px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #226cb0;
  border-radius: 9px;
  border: 1px solid #226cb0;
  width: 156px;
  margin-left: 18px;
}

.comment-send-btn-responsive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #226cb0;
  border-radius: 9px;
  border: 1px solid #226cb0;
  margin-left: 18px;
  width: 54px;
  height: 54px;
}

.comment-send-btn:hover {
  cursor: pointer;
}

.comment-send-btn-responsive:hover {
  cursor: pointer;
}

.comment-send-btn-responsive img {
  margin: 0;
}

.comment-send-btn p {
  width: 62px;
  height: 15px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #226cb0;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.comment-send-btn img {
  width: 12px;
  height: 12px;
}

.comment-send-btn-responsive img {
  width: 12px;
  height: 12px;
}

.candidate-users {
  background-color: #f4f4f4;
}

.candidate-user {
  color: #226cb0;
}

.applicant-jobs-container {
  margin-top: 36px;
}

.applicant-jobs-container > p {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #272727;
}

.applicant-jobs-sub-container {
  margin-top: 18px;
  display: flex;
  margin-left: -20px;
}

.applicant-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px;
  gap: 18px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  width: 247px;
  height: 238px;
  cursor: pointer;
  margin-left: 27px;
}

.applicant-add-date {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
}

.applicant-add-title {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #226cb0;
}

.applicant-add-site {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 400;
  color: #272727;
}

.applicant-ads-buttons-container {
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 54px;
  margin-top: 18px;
}

.applicant-cv-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 62px;
  gap: 10px;
  background: #226cb0;
  border-radius: 9px;
  width: 212px;
  height: 51px;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}

.applicant-dilig-cv-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 18px 42px;
  margin-left: 20px;
  margin-right: 15px;

  width: 212px;
  height: 51px;

  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-decoration: none;

  font-weight: 600;
  border: 1px solid #226cb0;
  background-color: white;
  color: #226cb0;
  
  border-radius: 9px;
  cursor: pointer;
}


.applicant-send-invite-btn {
  text-transform: uppercase;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 18px 52px;
  margin-left: 20px;
  margin-right: 15px;

  width: 212px;
  height: 51px;

  gap: 10px;
  font-weight: 600;
  border: 1px solid #226cb0;
  background-color: white;
  color: #226cb0;
  
  border-radius: 9px;
  cursor: pointer;
} 

.applicant-dilig-cv-btn-group {
  width: 212px;
  height: 51px;

  margin-right: 15px;
} 

.applicant-dilig-cv-btn {

  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;

  font-family: "Source Sans Pro" !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  letter-spacing: 0.04em !important;
  border: 1px solid #226cb0 !important;
  background-color: white !important;
  color: #226cb0 !important;
} 

.applicant-dilig-cv-btn-select {
  border-top-right-radius: 9px !important;
  border-bottom-right-radius: 9px !important;

  font-weight: 600 !important;
  border: 1px solid #226cb0 !important;
  background-color: white !important;
  color: #226cb0 !important;
} 

.applicant-jobs-back-button {
  margin-right: 36px;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #226cb0;
  cursor: pointer;
}

.tagStyle {
  color: #226cb0;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}

.comment-input {
  @extend .tagStyle;
  min-width: 368px;
  max-width: 368px;
}

.comment-input::placeholder {
  height: 20px;
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9d9d9d;
  flex: none;
}

.comment-input-list {
  @extend .tagStyle;
}

.comment-message-con {
  max-width: 400px;
}

.comment-container-header {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #272727;
}

.applicant-jobs-container-2 {
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 5rem;
}

.applicant-jobs-buttons-container {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 930px) {
  .comment-container {
    width: 500px;
  }
  .comment-input {
    width: 250px;
  }

  .comment-message-con {
    max-width: 300px;
  }
}

@media only screen and (max-width: 820px) {
  .comment-container {
    width: 400px;
  }

  .comment-input {
    width: 170px;
  }
  .comment-message-con {
    max-width: 200px;
  }
  .comment-send-btn {
    width: 90;
    padding: 10px 20px;
  }
  .send-comment-sub-container {
    height: 45px;
    margin-bottom: 20px;
  }
  .comment-input ::placeholder {
    font-size: 14px;
    line-height: 18px;
    padding-top: 2px;
  }
}

@media only screen and (max-width: 700px) {
  .comment-container {
    width: 380px;
  }
  .comment-message-con {
    max-width: 180px;
  }
  .comment-send-btn {
    width: 110;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 680px) {
  .content-candidate-container {
    flex-direction: column;
  }
  .comment-container {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 540px) {
  .candidate-header {
    height: 30px;
    font-family: Source Sans Pro;
    font-size: 30px;
    line-height: 30px;
  }

  .candidate-lower-header {
    font-size: 18px;
    line-height: 26px;
  }

  .applicant-cv-button {
    padding: 10px 52px;
    gap: 7px;
    border-radius: 7px;
    width: 180px;
    height: 40px;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .comment-container {
    width: 297px;
  }

  .comment-input {
    width: 100px;
  }

  .comment-input::placeholder {
    font-size: 12px;
    line-height: 15px;
    padding-top: -2px;
  }

  .comment-message-con {
    max-width: 130px;
  }
  .comment-send-btn {
    padding: 5px 5px;
  }
  .comment-container {
    padding: 20px;
  }

  .candidate-header {
    height: 20x;
    font-size: 20px;
    line-height: 20px;
  }

  .candidate-lower-header {
    font-size: 17px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 361px) {
  .main-candidate-container{
    padding-right: 36px;
  }
  .top-candidate-container {
    margin-left: 36px;
  }

  .content-candidate-container {
    margin-left: 36px;
  }

  .comment-input {
    @extend .tagStyle;
    min-width: 195px;
    max-width: 195px;
    height: 54px;
  }

  .comment-send-btn {
    width: fit-content;
  }

  .comment-input::placeholder {
    font-size: 10px;
    line-height: 12px;
    padding: 14px;
  }

  .comment-container {
    width: 300px;
    padding-left: 18px;
  }

  .content-candidate-container {
    justify-content: initial;
  }

  .applicant-jobs-container-2 {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .slick-list {
    padding-left: 0 !important;
  }

  .applicant-jobs-back-button {
    font-size: 14px;
    line-height: 18px;
    margin-right: 0;
  }
  .applicant-cv-button {
    padding: 18px 45px;
    width: 158px;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.04em;
  }

  .active-jobs-jobs-arrows {
    margin-left: -0.75rem;
  }

  .applicant-jobs-buttons-container {
    margin-left: 36px;
  }
}
