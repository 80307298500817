.day-component-container {
  display: flex;
  flex-direction: column;
  height: 143px;
  width: 178px;
  border: 1px solid #f4f4f4;
  padding: 17px;
  transition: 0.3s;
}

.day-component-container-placeholder {
  height: 143px;
  width: 178px;
  border: 1px solid #EDEDED;
  background-color: #FAFAFA;
  padding: 17px;
}

.day-component-container:hover
{
  cursor: pointer;
  scale: 1.04;
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
}

.day-component-container:hover
{
  border-radius: 12px;
  background: linear-gradient(0deg, #e8f7ff, #e8f7ff),
    linear-gradient(0deg, #226cb0, #226cb0);
  border: 1px solid #226cb0;
}

.day-component-day-informations-container {
  display: flex;
  margin-bottom: 5px;
}

.day-component-day-number {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: linear-gradient(0deg, #353535, #353535),
    linear-gradient(0deg, #272727, #272727);
}

.day-component-day-name {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  align-self: flex-end;
  margin-left: 3px;
}

.day-component-interviews-container {
  display: flex;
  padding: 3px;
  border-radius: 8px;
  border: 1px solid #EDEDED;
}

.day-component-interviews-name {
  font-family: Source Sans Pro;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #353535;
}

.day-component-more {
  height: 15px;
  width: fit-content;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #226CB0;
  font-style: normal; 
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 21px;
}
